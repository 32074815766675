.footer-container {
    display: flex;
    background-color: black;
    flex-direction: column;
}

.useful-links {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 3em;
}

.useful-links a {
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
}

.useful-links a:hover {
    color: var(--gold);
    border-bottom: 1px solid var(--gold);
}
.social-icons {
    display: flex;
    margin-left: 2.5em;

}

.social-icons img {
    width: 2em;
}

.footer-logo img {
    width: 6em;
    margin: 4em 0em 0em 2em;    
}

.copy-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em;
    background-color: var(--gold);
    margin-top: 2em;
    color: var(darkBlue);

}

.copy-right p {
    margin: .5em;
    overflow: hidden;
}


@media  only screen and (min-width: 768px) {
    .footer-container {
        display: flex;
        background-color: black;
        flex-direction: column;
        overflow: hidden;
        justify-content: center;
        text-align: center;      
    }

    .useful-links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        gap: 1em;
        padding: 3em;
        text-align: center;
    }

    .social-icons {
        justify-content: center;
        align-items: center;
        text-align: center;
    }


    

}