.nav-container {
    background-color:#0C0C28;
    display: flex;
    flex-wrap: wrap;
    padding: 2em;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;   
    width: 100%; 
    gap: 1.5em;
}

.logo {
    width: 7em;
}
.logo img {
    width: 6em;
    cursor: pointer;
}
 
.social-icons img {
    width:1.7em;
    cursor: pointer;
    margin: .1em;
    
}

.hamburger img {
    width: 2em;
    cursor: pointer;
}
.close {
    width: 2em;
    cursor: pointer;
}

.mobile-view a {
    text-decoration: none;
    padding: .5em;
    color: var(--gold);
    display: flex;
    flex-direction: column;
}

.mobile-view a:hover{
    color: #fff;
}

.mobile-view {
    background-color:#0C0C28;
    color: #fff;
    width: 100%;
}

.desktop-view {
    display: none;
}

@media (min-width: 601px) {

    .social-icons img {
        width:1.7em;
        cursor: pointer;
        margin: .1em;
        
    }
    
    .hamburger img {
        display: none;
    }
    .close {
        display: none;
    }
    
    .desktop-view {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
    }
    
    .desktop-view a {
        text-decoration: none;
        color: var(--gold);
        display: flex;
        gap: 1em;
    }
    .desktop-view a:hover {

        color: #fff;
        border-bottom: 2px solid var(--gold);
      
    }


    
    
    
  }