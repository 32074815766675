.landing-page-container {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.landing-background {
    height: 600px;
    width: 100%;
    background-color: #0C0C28;    
    
}

.landing-background img {
    width: 100%;
    height: 100%;
    opacity: .2;
    object-fit: contain;
}

.landing-background-text {
    padding: 2em;
    position: absolute;
}

.landing-background-text h4 {
    margin-bottom: 1em;
    color: var(--gold)
}

.landing-background-text h1 {
    color: #fff;    
    font-size: 2.3rem;
}

.landing-background-text p {
    color: #fff;
    font-size: 1.2rem;
    margin-top: .7em;
}

.landing-background-text button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: bold;
    padding: 1em;
    border: none;
    border-radius: .2em;
    margin-top: 2em;
}


.landing-background-text button a {
    text-decoration: none;
    color: var(---darkBlue)
}
.right-arrow {
    width: 2em;
    rotate: 90deg;
    padding: 0;
    margin: 0;
}

.landing-background-text button:hover {
    background-color: var(--gold);
}



@media only screen and (min-width: 768px) {
    .landing-background-text {
        padding: 3em 5em;
    }

    .landing-background-text h4 {
        margin-bottom: 1em;
        color: var(--gold);
        font-size: 2rem;
    }
    
    .landing-background-text h1 {
        color: #fff;    
        font-size: 3rem;
    }
    
    .landing-background-text p {
        color: #fff;
        font-size: 1.2rem;
        margin-top: .7em;
    }    

    
}

@media  only screen and (min-width: 1024px) {

    .landing-background-text {
        padding: 5em 10em;
    }

    .landing-background-text h4 {
        margin-bottom: 1em;
        color: var(--gold);
        font-size: 2rem;
    }
    
    .landing-background-text h1 {
        color: #fff;    
        font-size: 3rem;
    }
    
    .landing-background-text p {
        color: #fff;
        font-size: 1.2rem;
        margin-top: .7em;
    }   
    
}