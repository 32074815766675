.home-second-section-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.quality-work {
    display: flex;
    flex-direction: column;
}

.quality-work-background {
    width: 100%;
    height: 370px;
    padding: 0;
}

.quality-work-background img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: .1;
}

.quality-work-text {
    padding: 2em;
}

.quality-work-text h2 {
    color: var(--gold);
    margin-bottom: .7em;
}
.quality-work-text p {
    font-size: 1rem;
    line-height: 1.5em;
}

.our-story {
    background-color: var(--darkBlue);
    display: flex;
    flex-direction: column;
}

.story-image {
    padding: 2em;
}

.story-image img {
    width: 100%;
    margin-top: 2em;
    border-radius: .5em .5em 5em .5em;
}

.story-text {
    padding: 2em;
}

.story-text h2 {
    color: var(--gold);
    margin-bottom: 1em;
}

.story-text p {
    font-size: 1rem;
    line-height: 1.5em;
    color: #fff;
}

.story-text button {
    margin-top: 2em;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: .4em;
}


.story-text button a {
    text-decoration: none;
    font-size: 1rem;
    color: var(--darkBlue);
    font-weight: bold;
}

.story-text button:hover {
    color: #fff;
    background-color: var(--gold);
}
@media  only screen and (min-width: 768px) {

    .quality-work {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .quality-work-background {
        padding: 0;
        width: 60%;
        height: 320px;;
    }
    
    .quality-work-background img {
        width: 100%;
        height: 100%;
        object-fit:contain;
        opacity: .1;
    }
    
    .quality-work-text {
        padding: 2em;
        width: 50%;
    }

    .our-story {
        flex-direction: row;
        padding: 2em;
    }

    .story-image {
        padding: 2em;
        width: 50%;
    }
    
    .story-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-top: 2em;
        border-radius: .5em .5em 5em .5em;
    }
    
    .story-text {
        padding: 2em;
        width: 50%;
    }
    

    


    
}
@media  only screen and (min-width: 1024px) {
    .story-image {
        padding: 2em;
        width: 40%;
    }

    .story-text {
        padding: 2em;
        width: 60%;
    }

}