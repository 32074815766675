.contact-hero-section-container {
    display: flex;
    flex-direction: column;
}

.contact-hero-background {
    height: 350px;
    width: 100%;
    background-color: var(--darkBlue);
} 

.contact-hero-background img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: .2;
}

.contact-background-text {
    position: absolute;
    padding: 2em;
}

.contact-background-text h2 {
    color: var(--gold);
    margin-bottom: 1em;
}

.contact-background-text p {
    color: #fff;
    line-height: 2em;
}

@media  only screen and (min-width: 768px) {
    .contact-background-text {
        padding: 5em;
    }
    
    .contact-background-text h2 {
        margin-bottom: .4em;
        font-size: 3rem;
    }
    
    .contact-background-text p {
        color: #fff;
        line-height: 2em;
        font-size: 1.5rem;
    }
}

@media  only screen and (min-width: 1024px) {

    .contact-background-text {
        padding: 5em;
    }
    
    .contact-background-text h2 {
        margin-bottom: .2em;
        font-size: 5rem;
    }
    
}