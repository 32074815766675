.feedback-container {
    padding: 2em;
}

.feedback-text h2 {
    margin-bottom: 1em;
}
.feedback-text h3 {
    margin-bottom: 1em;
}

.feedback-text h4 {
    margin: 1em 0em 0em 0em;
}
.feedback-container form {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
}

.feedback-container form input {
    padding: .8em;
    margin: .5em 0em;
    border: 2px solid var(--gold);
    border-radius: .3em;
}

.feedback-container form textarea {
    border: 2px solid var(--gold);
    padding: 1em;
}

.submit-btn {

   background-color: var(--gold);
   font-size: 1rem;
   font-weight: bold;
   color: #fff;
   padding: 1em;
   border: none;
   margin-top: 1em;
}


.submit-btn:hover {
    background-color: var(--blue);
    border: none;
}

@media  only screen and (min-width: 768px) {

    .feedback-container {
        padding: 2em;
        display: flex;
        gap: 2.3em;
        align-items: center;
        justify-content: center;
    }
    .feedback-text {
        width: 50%;
    }
    

    
}
@media  only screen and (min-width: 1024px) {

    .feedback-text h2 {
        font-size: 2rem;
    }
    .feedback-text h3 {
        margin-bottom: 1em;
        font-size: 2rem;
    }
    
    .feedback-text h4 {
        margin: 1em 0em 0em 0em;
        font-size: 1.5rem;
    }

    .feedback-text p {
        font-size: 1.2rem;
        margin-top: .2em;
    }
}