@font-face {
  font-family: myFont ;
  src: url('../Fonts/MonaSans-Regular.ttf');
}

:root {
  --blue: #080E6A;
  --gold: #CFAC55;
  --darkBlue: #0C0C28;
}

* {
  padding: 0;
  margin:0;
  box-sizing: border-box;
  font-family:'myFont';
}

