.faq-second-section {
    display: flex;
    flex-direction: column;
    background-color: lightgray;
    padding: 1em;
}

.faq-second-section h2 {
    padding: 2em;
}

.section-1, .section-2, .section-3, .section-4, .section-5 {
    padding: 2em;
    background-color: #fff;

}

.quest {
    font-weight: bold;
    border-top: 1.9px solid lightgray;
    padding: 1em 0em;
    line-height: 2em;
    margin: 1em 0em;
}



@media  only screen and (min-width: 768px) {

    .section-1, .section-2, .section-3, .section-4, .section-5 {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: flex-start;
        gap: 1em;    
    }

    


}