.principle-container {
    display: flex;
    flex-direction: column;
    background-color: lavender;
}

.principle-tabs {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: bold;
    padding: 2em;
}

.tab-1, .tab-2, .tab-3 {
    cursor: pointer;
    color: var(--blue);
    padding: 1em;

}
.tab-1:hover, .tab-2:hover, .tab-3:hover {
    color: var(--gold);
}

.active-tab {
    background-color: #fff;
    border-radius: .2em;
}

.vision, .mission, .value {
    padding: 2em;
    font-size: 1rem;
    font-weight: 200;
    line-height: 1.8em;
    background-color: #fff;
    border-radius: .5em .5em 6em .5em;
    margin: 1em;
}

@media  only screen and (min-width: 768px) {
    .principle-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        padding: 2em;
    }

    .principle-tabs {
       width: 40%;
    }

    .principle-texts {
        width: 60%;
    }


    
}