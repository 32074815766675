.home-third-section-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-third-section-container h2  {
  padding: 2em 1.3em 0em 1.3em;
}

.home-third-section-container p {
   padding: 0em 2em;
   margin: .6em 0em 3em 0em; 
}

.hospitality {
    display: flex;
    flex-direction: column;
    position: relative; 
    margin: 1.4em;
}

.hospitality-image {
    height: 350px;
    width:  300px;
    background-color: var(--darkBlue);
}

.hospitality-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: .5em;
    opacity: .6;
}

.hospitality-text{
    position: absolute;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 350px;
}

.hospitality-text h3 {
    padding: 2em 2em 0em 2em;    
    text-align:center;
}

.hospitality-text p {
    text-align: center;
    line-height: 2em;
}

.hospitality-text button {
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hospitality-text button a {
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    color: var(--darkBlue);
}


.recruitment {
    display: flex;
    flex-direction: column;
    position: relative; 
    margin: 1.4em;
}

.recruitment-image {
    height: 350px;
    width:  300px;
    background-color: var(--darkBlue);
}

.recruitment-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: .5em;
    opacity: .3;
}

.recruitment-text{
    position: absolute;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 350px;
}

.recruitment-text h3 {
    padding: 2em 2em 0em 2em;    
    text-align:center;
}

.recruitment-text p {
    text-align: center;
    line-height: 2em;
}

.recruitment-text button {
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.recruitment-text button a {
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    color: var(--darkBlue);
    
}

.travel {
    display: flex;
    flex-direction: column;
    position: relative; 
    margin: 1.4em;
}

.travel-image {
    height: 350px;
    width:  300px;
    background-color: var(--darkBlue);
}

.travel-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: .5em;
    opacity: .3;
}

.travel-text{
    position: absolute;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 350px;
}

.travel-text h3 {
    padding: 2em 2em 0em 2em;    
    text-align:center;
}

.travel-text p {
    text-align: center;
    line-height: 2em;
}

.travel-text button {
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.travel-text button a {
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    color: var(--darkBlue);
    
}

.loan {
    display: flex;
    flex-direction: column;
    position: relative; 
    margin: 1.4em;
}

.loan-image {
    height: 350px;
    width:  300px;
    background-color: var(--darkBlue);
}

.loan-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: .5em;
    opacity: .3;
}

.loan-text{
    position: absolute;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 350px;
}

.loan-text h3 {
    padding: 2em 2em 0em 2em;    
    text-align:center;
}

.loan-text p {
    text-align: center;
    line-height: 2em;
}

.loan-text button {
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loan-text button a {
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    color: var(--darkBlue);
    
}

.training {
    display: flex;
    flex-direction: column;
    position: relative; 
    margin: 1.4em;
}

.training-image {
    height: 350px;
    width:  300px;
    background-color: var(--darkBlue);
}

.training-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: .5em;
    opacity: .3;
}

.training-text{
    position: absolute;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 350px;
}

.training-text h3 {
    padding: 2em 2em 0em 2em;    
    text-align:center;
}

.training-text p {
    text-align: center;
    line-height: 2em;
}

.training-text button {
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.training-text button a {
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    color: var(--darkBlue);
    
}


@media  only screen and (min-width: 768px) {
    .home-third-section-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .home-third-section-text h2  {
        text-align: center;
      }
      
      .home-third-section-text p {
         text-align: center;
         font-size: 1.4rem;
      }
      
      .home-third-section-cards {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }



    

}