.why-us-container {
    display: flex;
    flex-direction: column;
}

.why-text {
    padding: 2em;
}

.why-text h2{
    margin-bottom: 1em;
}

.why-text p {
    line-height: 2em;
}

.why-us-cards {
    padding: 2em;
}

.card-1, .card-2, .card-3, .card-4 {
    margin: 1em 0em;
    border-bottom: 1px solid lightgray;
    padding-bottom: 1.4em;
}
.card-1 h3, .card-2 h3, .card-3 h3, .card-4 h3 {
    margin-bottom: 1em;
    color: var(--blue)
}


@media  only screen and (min-width: 768px) {
    .why-text {
        padding: 2em;
        text-align: center;
    }

    .why-text h2{
        margin-bottom: 1em;
        font-size: 2rem;
    }
    
    .why-text p {
        line-height: 2.4em;
        font-size: 1.4rem;
    }

    .card-1, .card-2, .card-3, .card-4 {
        width: 100%;
    }

    .card-1 h3, .card-2 h3, .card-3 h3, .card-4 h3 {
        font-size: 2rem;
    }

    .card-1 p, .card-2 p, .card-3 p, .card-4 p {
        font-size: 1.5rem;
    }

    



    .why-us-cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2em;
        padding: 3em;
    }
    

}

@media  only screen and (min-width: 1024px) {
    .why-us-cards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2em;
        padding: 3em;
    }
    
}
